import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { CaretDown, CaretUp } from "@phosphor-icons/react";
import ButtonKMQ from "../../../../components/UI/Button/ButtonKMQ";
import { useQueryDocumentRequests } from "../../../../api/admin/useQueryDocumentRequests";
import { useMutateWithdrawRequest } from "../../../../api/admin/useMutateWithdrawRequest";
import { ButtonTypes } from "../../../../constants/ui/types/ButtonTypes";
import "./previous-requests.scss";
import { DocumentRequest } from "../../../../constants/types/DocumentRequest";

const PreviousRequests = () => {
  const { portfolio_id } = useParams<{ portfolio_id: string }>();
  const [expandedRequest, setExpandedRequest] = useState<string | null>(null);
  const [withdrawingId, setWithdrawingId] = useState<string | null>(null);

  const {
    data: documentRequests,
    isLoading,
    error,
  } = useQueryDocumentRequests({
    portfolio_id: portfolio_id || "",
  });

  const withdrawRequest = useMutateWithdrawRequest();

  const toggleExpand = (id: string) => {
    setExpandedRequest(expandedRequest === id ? null : id);
  };

  const handleWithdraw = async (requestId: string) => {
    if (withdrawingId) return;

    setWithdrawingId(requestId);
    try {
      await withdrawRequest.mutateAsync({
        document_request_id: requestId,
      });
    } catch (error) {
      console.error("Error withdrawing request:", error);
    } finally {
      setWithdrawingId(null);
    }
  };

  if (isLoading) return <div>Loading previous requests...</div>;
  if (error) return <div>Error loading previous requests</div>;
  if (!documentRequests?.length) return <div>No previous requests found</div>;

  return (
    <div className="previous-requests">
      <h2>Previous Request</h2>
      <div className="requests-list">
        <div className="request-header">
          <span>Request Title</span>
          <span>Request Date</span>
          <span>Request Status</span>
          <span>Actions</span>
        </div>
        {documentRequests.map((request: DocumentRequest) => (
          <div key={request.id} className="request-item-container">
            <div className="request-item">
              <span>{request.title}</span>
              <span>{new Date(request.created_at).toISOString().slice(0, 10)}</span>
              <span className="status">{request.status}</span>
              <button
                className="more-detail-btn"
                onClick={() => toggleExpand(request.id)}
              >
                {!expandedRequest && "More Detail"}
                {expandedRequest === request.id ? (
                  <CaretUp size={25} />
                ) : (
                  <CaretDown size={25} />
                )}
              </button>
            </div>
            {expandedRequest === request.id && (
              <div className="request-details">
                <div className="detail-row">
                  <span className="detail-label">Paid course</span>
                  <span className="detail-value">
                    {`${request.paid_course.course.code} ${request.paid_course.course.title}`}
                  </span>
                </div>
                <div className="detail-row">
                  <span className="detail-label">Additional note</span>
                  <span className="detail-value">{request.notes}</span>
                </div>
                {request.status === "Request sent" && !request.inactive && (
                  <div className="detail-actions">
                    <ButtonKMQ
                      onClick={() => handleWithdraw(request.id)}
                      disabled={withdrawingId === request.id}
                      type={ButtonTypes.Secondary}
                    >
                      {withdrawingId === request.id
                        ? "Withdrawing..."
                        : "Withdraw request"}
                    </ButtonKMQ>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PreviousRequests;