import { useQuery } from "@tanstack/react-query";
import { useAxios } from "../../hooks/axios/useAxios";

import { paidCoursesUrl } from "../../constants/api/endpoints";

interface PrerequisiteCourse {
	code: string;
}

interface Course {
	id: number;
	code: string;
	title: string;
	description: string;
	type: string | null;
	matching_score: number;
	reviewer_name: string | null;
	reviewed_at_date: string | null;
	approved: boolean | null;
	course_recommendation_id: string;
	cpl_eligible: boolean;
	learning_outcome_html: string;
	prerequisite_courses: PrerequisiteCourse[];
	credit_hours: string;
	location: string;
	payment_status: string;
	eligible_for_payment: boolean;
	catalogue_version_number: string;
}

interface ApiResponse {
	courses: Course[];
	education_institution_id: string;
	catalogue_url: string;
}

export interface PaidCourse {
	id: string;
	name: string;
}

interface GetPaidCoursesParams {
	portfolio_id?: string | null;
}

export const useQueryPaidCourses = ({ portfolio_id }: GetPaidCoursesParams) => {
	const axios = useAxios();

	const handleRequest = async () => {
		if (!portfolio_id) {
			throw new Error("Portfolio ID is required");
		}

		try {
			const response = await axios.get<ApiResponse>(paidCoursesUrl, {
				params: {
					portfolio_id,
					paid: true,
				},
			});

			//   console.log('API Response:', response.data);

			if (!response.data || !response.data.courses) {
				throw new Error("Invalid response format");
			}

			const transformedCourses: PaidCourse[] = response.data.courses.map(
				(course) => ({
					id: course.course_recommendation_id,
					name: `${course.code} ${course.title}`,
				})
			);

			return transformedCourses;
		} catch (error) {
			console.error("Error fetching paid courses:", error);
			throw error;
		}
	};

	return useQuery<PaidCourse[]>({
		queryKey: ["paidCourses", portfolio_id],
		queryFn: handleRequest,
		enabled: Boolean(portfolio_id),
		retry: 1,
		staleTime: 5 * 60 * 1000,
	});
};
