import './create-admin.scss';
import {ChangeEvent, useEffect, useState} from "react";
import InputKMQ from "../../../components/UI/Input/InputKMQ";
import ButtonKMQ from "../../../components/UI/Button/ButtonKMQ";
import {useCreateAdmin} from "../../../api/admin/useCreateAdmin";
import Loading from "../../../components/UI/Loading/Loading";
import {toast} from "react-toastify";

interface IAdmin {
  first_name?: string,
  last_name?: string,
  email?: string,
}

const CreateAdmin = () => {
  const [newAdmin, setNewAdmin] = useState<IAdmin>({});
  const {
    mutate,
    isPending,
    isSuccess,
    error
  } = useCreateAdmin();

  const handleCreate = () => {
    mutate(newAdmin);
  }

  useEffect(() => {
    if (isSuccess) {
      toast.success('Admin created succecfully!');
      setNewAdmin({});
    }
  }, [isSuccess])

  return <div className={'create-admin-container'}>
    <Loading loading={isPending}/>
    <div className={'create-admin'}>
      <div className={'create-admin-title'}>
        Create Admin
      </div>

      <InputKMQ
        value={newAdmin.first_name ? newAdmin.first_name : ''}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setNewAdmin({...newAdmin, first_name: e.target.value})
        }
        type={'text'}
        placeholder={'First name'}
        label={"Admin's First Name"}
      />
      <InputKMQ
        value={newAdmin.last_name ? newAdmin.last_name : ''}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setNewAdmin({...newAdmin, last_name: e.target.value})
        }
        type={'text'}
        placeholder={'Last name'}
        label={"Admin's Last Name"}
      />
      <InputKMQ
        value={newAdmin.email ? newAdmin.email : ''}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setNewAdmin({...newAdmin, email: e.target.value})
        }
        type={'text'}
        placeholder={'Email'}
        label={"Admin's Email"}
        error={error ? 'User with this email already exists' : undefined}
      />

      <div className={'create-admin-submit'}>
        <ButtonKMQ onClick={handleCreate}
                   disabled={!newAdmin.first_name || !newAdmin.last_name || !newAdmin.email}>
          Create account
        </ButtonKMQ>
      </div>
    </div>
  </div>
}

export default CreateAdmin;