import React, { useRef } from "react";
import { CloudArrowUp, Paperclip, XCircle } from "@phosphor-icons/react";
import "./evidence-dropzone.scss";

interface DropzoneProps {
  onFileUploaded: (file: File) => void;
  file: File | undefined;
  title: string | undefined;
  status: string;
}

const EvidenceDropzone: React.FC<DropzoneProps> = ({
  onFileUploaded,
  file,
  title,
  status,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      onFileUploaded(files[0]);
    }
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const newFile = event.dataTransfer.files[0];
    if (newFile) {
      onFileUploaded(newFile);
    }
  };

  const handleBrowseClick = () => {
    inputRef.current?.click();
  };

  const removeFile = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    onFileUploaded(null as unknown as File);
  };

  const getStatusMessage = () => {
    switch (status) {
      case "Request resolved":
        return "This document has already been uploaded";
      case "Request withdrawn":
        return "This document request has been withdrawn";
      default:
        return "Please upload the requested documents at your earliest convenience";
    }
  };

  const isActive = status === "Request sent";
  const showDropzone = isActive;

  return (
    <div className="evidence-dropzone">
      <div className="evidence-dropzone-submission">
        {getStatusMessage()}
      </div>
      {isActive && (
        <>
          <div className="evidence-dropzone-request">
            <div className="evidence-dropzone-request-title">
              Request title:
            </div>
            <div className="evidence-dropzone-request-text">{title}</div>
          </div>
          <div className="evidence-dropzone-request">
            <div className="evidence-dropzone-request-title">
              Additional note:
            </div>
            <div className="evidence-dropzone-request-text">
              Please provide your {title} as soon as possible
            </div>
          </div>
          <div className="evidence-dropzone-note">
            * You can only upload one file at a time
          </div>
        </>
      )}
      {showDropzone && (
        <>
          <div
            className="dropzone-container"
            onDragOver={(event) => event.preventDefault()}
            onDrop={onDrop}
          >
            <div className="dropzone-content">
              <div className="dropzone-icon">
                <CloudArrowUp size={56} color="#212121" />
              </div>
              <h2 className="dropzone-title">Drag your file here</h2>
              <p className="dropzone-text">
                Please only use the following file formats: .docx, .doc, .pdf,
                .jpg, .png
                <br />
                Max files size: 10MBs | Max files: 1
              </p>
              {file ? (
                <div className="file-info">
                  <span className="file-icon">
                    <Paperclip size={21} color="#212121" />
                  </span>
                  <span className="file-name">{file.name}</span>
                  <button className="file-remove" onClick={removeFile}>
                    <XCircle size={21} color="#212121" />
                  </button>
                </div>
              ) : null}
              <input
                ref={inputRef}
                type="file"
                accept=".docx,.doc,.pdf,.jpg,.png"
                hidden
                onChange={onInputChange}
              />
            </div>
          </div>
          <div className="evidence-dropzone-divider">OR</div>
          <div className="evidence-dropzone-browse" onClick={handleBrowseClick}>
            <span>Browse</span> your computer
          </div>
        </>
      )}
    </div>
  );
};

export default EvidenceDropzone;