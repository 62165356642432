import React, { useContext, useEffect, useState } from "react";
import "./evidence-submission.scss";
import EvidenceDropzone from "../../../components/EvidenceDropzone/EvidenceDropzone";
import AlertModal from "../../../components/UI/Modals/AlertModal/AlertModal";
import ButtonKMQ from "../../../components/UI/Button/ButtonKMQ";
import { useDocumentRequests } from "../../../api/cpl-applicant/useQueryDocumentRequests";
import { useSubmitDocument } from "../../../api/cpl-applicant/useMutateSubmitDocument";
import { PortfolioContext } from "../../../hooks/Context/PortfolioContext";

interface DocumentRequest {
  id: string;
  title: string;
  notes: string;
  status: string;
  created_at: string;
}

const EvidenceSubmission: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("");
  const [submittedTabs, setSubmittedTabs] = useState<string[]>([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<Record<string, File>>({});
  const [portfolio] = useContext(PortfolioContext);

  const { data: allDocumentRequests = [] } = useDocumentRequests({ 
    // @ts-ignore
    portfolio_id: portfolio?.id 
  });

  // filter out withdrawn requests
  const documentRequests = allDocumentRequests.filter(
    doc => doc.status !== "Request withdrawn"
  );

  useEffect(() => {
    if (documentRequests.length > 0 && !activeTab) {
      setActiveTab(documentRequests[0].id);
    }
  }, [documentRequests]);

  const submitDocument = useSubmitDocument();

  if (!portfolio?.id) {
    return (
      <div className="evidence-submission">
        <div className="evidence-submission-message">
          Please finish your application to view evidence requests
        </div>
      </div>
    );
  }

  const handleFileUpload = (documentId: string, file: File) => {
    setUploadedFiles(prev => ({
      ...prev,
      [documentId]: file
    }));
  };

  const handleSubmit = async () => {
    if (!uploadedFiles[activeTab]) return;

    try {
      await submitDocument.mutateAsync({
        document_request_id: activeTab,
        file: uploadedFiles[activeTab],
      });
      
      setSubmittedTabs(prev => [...prev, activeTab]);
      setShowSuccessModal(true);
      
      setUploadedFiles(prev => {
        const newFiles = { ...prev };
        delete newFiles[activeTab];
        return newFiles;
      });
    } catch (error) {
      console.error('Error submitting document:', error);
    }
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
  };

  if (documentRequests.length === 0) {
    return (
      <div className="evidence-submission">
        <div className="evidence-submission-message">
          No document requests found
        </div>
      </div>
    );
  }

  const activeDocument = documentRequests.find(doc => doc.id === activeTab);
  const canSubmit = uploadedFiles[activeTab] && activeDocument?.status !== "Request resolved";

  return (
    <div className="evidence-submission">
      <div className="evidence-submission-title">Evidence Submission</div>
      <div className="evidence-submission-content">
        <div className="tabs">
          {documentRequests.map((doc: DocumentRequest, index: number) => (
            <React.Fragment key={doc.id}>
              <button
                className={`tab-button ${activeTab === doc.id ? "active" : ""} ${
                  doc.status === "Request resolved" ? "resolved" : ""
                }`}
                onClick={() => setActiveTab(doc.id)}
              >
                {doc.title}
              </button>
              {index < documentRequests.length - 1 && (
                <span className="tab-separator">|</span>
              )}
            </React.Fragment>
          ))}
        </div>
        <div className="tab-content">
          {documentRequests.map((doc: DocumentRequest) =>
            activeTab === doc.id ? (
              <div key={doc.id}>
                <EvidenceDropzone
                  onFileUploaded={(file) => handleFileUpload(doc.id, file)}
                  title={doc.title}
                  file={uploadedFiles[doc.id]}
                  status={doc.status}
                />
                {doc.status !== "Request resolved" && (
                  <div className="submit-section">
                    <ButtonKMQ
                      onClick={handleSubmit}
                      disabled={!canSubmit}
                    >
                      Submit
                    </ButtonKMQ>
                  </div>
                )}
              </div>
            ) : null
          )}
        </div>
      </div>
      <AlertModal
        open={showSuccessModal}
        onClose={handleCloseModal}
        text="Your document has been successfully uploaded."
        proceedText="Close"
        onProceed={handleCloseModal}
      />
    </div>
  );
};

export default EvidenceSubmission;