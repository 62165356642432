import { useFileDownload, BaseDownloadParams } from './useFileDownload';
import { documentUploadEndpointUrl } from '../../constants/api/endpoints';

export interface DocumentDownloadParams extends BaseDownloadParams {
  document_request_id?: string;
  portfolio_id?: string;
}

export const useDocumentDownload = () => {
  const { downloadFile } = useFileDownload();

  const downloadDocument = async (params: DocumentDownloadParams) => {
    return downloadFile<DocumentDownloadParams>(documentUploadEndpointUrl, params);
  };

  return { downloadDocument };
};