import { useFileDownload, BaseDownloadParams } from './useFileDownload';
import { portfolioResumeEndpointUrl } from '../../constants/api/endpoints';

export interface ResumeDownloadParams extends BaseDownloadParams {
  portfolio_id: string;
}

export const useResumeDownload = () => {
  const { downloadFile } = useFileDownload();

  const downloadResume = async (params: ResumeDownloadParams) => {
    return downloadFile<ResumeDownloadParams>(portfolioResumeEndpointUrl, params);
  };

  return { downloadResume };
};