import { useNavigate } from 'react-router-dom';
import './document-request-button.scss';
import { FileDoc } from '@phosphor-icons/react';

interface DocumentRequestProps {
  applicantId: string | number;
}

const DocumentRequestButton = ({ applicantId }: DocumentRequestProps) => {
    const navigate = useNavigate();
  
  const content = (
    <>
      <FileDoc size={32} weight="regular" />
      <span>Request documents</span>
    </>
  );

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate(`/cpl-admin/${applicantId}/request-document-upload`);
  };

  return (
    <div
      className="document-request document-request-link"
      onClick={handleClick}
    >
      {content}
    </div>
  );
};

export default DocumentRequestButton;